<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <section class="tiny padded transparent main">
    <h1><translate translate-context="*/*/*/Noun">Downloads</translate></h1>
    <loading-area
      class="opaque padded bordered"
      v-if="versions === null"
      @loaded="versions = $event"
      :apiUrl="$store.state.downloadsUrl"
    >
      <span><translate translate-context="*/*/Loader">Loading downloads…</translate></span>
    </loading-area>
    <template v-else>
      <version-card
        v-for="version in versions"
        :key="version.version"
        :version="version"
        class="mb-regular card"
      >
      </version-card>
    </template>
  </section>
</template>

<script>
import VersionCard from '@/components/VersionCard'

export default {
  components: {
    VersionCard
  },
  data () {
    return {
      versions: null,
    }
  },
}
</script>